import React, { useCallback } from 'react'
import BlogCarousel from '../../components/blocks/BlogCarousel'
import ProductItem from '../../components/blocks/ProductItem'
import ProductItemDetails from '../../components/blocks/ProductItemDetails'
import LoadableImage from '../../components/elements/LoadableImage'
import priceFormatter from '../../components/elements/PriceFormatter'

const settings = {
  dots: false,
  infinite: false,
  lazyLoad: 'ondemand',
  speed: 500,
  slidesToShow: 4.5,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        dots: false,
        slidesToShow: 1.4,
        slidesToScroll: 1
      }
    }
  ]
}

const Carousel = props => {
  const handleDataLayer = useCallback(() => {
    window.dataLayer.push({
      amberCatId: props.pathname,
      pageType: 'trendPageTemplate - slider',
      event: 'productClick'
    })
  }, [props])
  return (
    <BlogCarousel>
      <BlogCarousel.Slider {...settings}>
        {props.data.map(item => (
          <BlogCarousel.Item key={item}>
            <BlogCarousel.Wrapper>
              <ProductItem>
                <ProductItem.StyledLink
                  href={item.deeplink || item.deepLink}
                  rel='nofollow noopener'
                  target='_blank'
                  onClick={handleDataLayer}
                >
                  <LoadableImage src={item.imgUrl1} alt={item.prodTitle} />
                </ProductItem.StyledLink>
                <ProductItemDetails>
                  <ProductItemDetails.H3>
                    <ProductItemDetails.StyledLink href={item.deeplink || item.deepLink}>
                      {item.prodTitle}
                    </ProductItemDetails.StyledLink>
                  </ProductItemDetails.H3>
                  <ProductItemDetails.Price>
                    <ProductItemDetails.ActualPrice>
                      {priceFormatter(item.prodPrice)}
                    </ProductItemDetails.ActualPrice>
                    {item.prodPriceOld && (
                      <ProductItemDetails.Discount>
                        {priceFormatter(item.prodPriceOld)}
                      </ProductItemDetails.Discount>
                    )}
                  </ProductItemDetails.Price>
                </ProductItemDetails>
              </ProductItem>
            </BlogCarousel.Wrapper>
          </BlogCarousel.Item>
        ))}
      </BlogCarousel.Slider>
    </BlogCarousel>
  )
}

export default Carousel
