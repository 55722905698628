import styled, { css } from 'styled-components'

const bold = css`
  font-weight: bold !important;
`

const centered = css`
  text-align: center;
`

const TitleCSS = css`
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 12px;
  color: red;
  margin-top: 53px;
  transition: all 700ms ease;
  overflow-wrap: break-word;
  font-size: 22px;

  &:hover,
  &:hover a {
    text-decoration: none;
  }
`

const Title = styled.h2`
  ${TitleCSS}
  ${props => props.bold && bold}
  ${props => props.centered && centered}
`

export { TitleCSS }
export default Title
