import InnerHTML from 'dangerously-set-html-content'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import Grid, { grid } from 'styled-components-grid'
import GridUnit from 'styled-components-grid/dist/cjs/components/GridUnit'
import BlogBanner from '../../components/blocks/BlogBanner'
import LandPagePost from '../../components/blocks/LandPagePost'
import LandPagePost2 from '../../components/blocks/LandPagePost2'
import Layout from '../../components/layouts/layout'
import Carousel from './Carousel'
import ItemList from './ItemList'

const Container = styled.div`
  ${grid({})};
  padding: 0 240px;
  margin-top: 40px;
  margin-bottom: 103px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1399px) {
    padding: 0 60px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 15px;
    margin-bottom: 53px;
  }
`

const GridWithPadding = styled.div`
  ${grid.unit({ size: { sm: 1, md: 1 / 2 } })};
  padding: 10px;
`

const BlogHeader = item => {
  if (!item?.node?.mediaImage1) return null

  if (item.node.image1Clickid) {
    return (
      <a
        rel='nofollow noopener noreferrer'
        target='_blank'
        href={
          item.node.image1Clickid.indexOf('/') >= 0
            ? item.node.image1Clickid
            : '/clickout?click_id=' + item.node.image1Clickid
        }
      >
        <BlogBanner.Image
          fluid={item.node.mediaImage1.fluid}
          alt={item.node.image1Title}
        />
      </a>
    )
  }

  return (
    <BlogBanner.Image
      fluid={item.node.mediaImage1.fluid}
      alt={item.node.image1Title}
    />
  )
}

const TrendPageTemplate = ({ pageContext, location, db, ...props }) => {
  const {
    header,
    headerSubtitle,
    textBlock1,
    textBlock2,
    textCarousel1,
    textCarousel2,
    textBlock3,
    linkInstagram,
    textInstagram,
    textCarousel3,
    textCarousel4,
    textBlock4,
    seoText
  } = pageContext.data.contentful.node

  return (
    <>
      <Helmet
        title={pageContext.data.seo_title}
        meta={[
          {
            name: 'description',
            content: pageContext.data.seo_description
          },
          {
            name: 'keywords',
            content: pageContext.data.keywords
          },
          {
            name: 'robots',
            content: pageContext.data.index
          },
          {
            name: 'og:type',
            content: 'article'
          },
          {
            name: 'og:title',
            content: pageContext.data.seo_title
          },
          {
            name: 'og:description',
            content: pageContext.data.seo_description
          },
          {
            name: 'og:url',
            content: `https://amber-living.de${props.path}`
          },
          {
            name: 'og:site_name',
            content: 'AmberLiving'
          },
          {
            name: 'article:published_time',
            content: pageContext.data.contentful.node.createdAt
          },
          {
            name: 'article:author',
            content: pageContext.data.contentful.node.author
          }
        ]}
        link={[
          {
            rel: 'canonical',
            href: pageContext.data.canonical
          }
        ]}
      />
      <Layout navigation={pageContext.navigation}>
        <Container>
          <GridUnit size={{ xs: 1, sm: 1 / 3 }}>
            <BlogBanner.Box>
              <BlogBanner.BoxText>{header}</BlogBanner.BoxText>
              <BlogBanner.BoxBottomText>
                {headerSubtitle}
              </BlogBanner.BoxBottomText>
            </BlogBanner.Box>
          </GridUnit>
          <GridUnit size={{ xs: 1, sm: 2 / 3 }}>
            <BlogHeader {...pageContext.data.contentful} />
          </GridUnit>
        </Container>
        <LandPagePost.Affect>
          <LandPagePost>
            <ReactMarkdown source={textBlock1.textBlock1} />
          </LandPagePost>
          <LandPagePost>
            <Grid>
              {pageContext.data.contentful.node.mediaImage2 && (
                <GatsbyImage
                  fluid={pageContext.data.contentful.node.mediaImage2.fluid}
                />
              )}
              <ReactMarkdown source={textBlock2.textBlock2} />
            </Grid>
          </LandPagePost>
          <LandPagePost>
            <ReactMarkdown source={textCarousel1.textCarousel1} />
          </LandPagePost>
          <LandPagePost2>
            <Carousel
              data={pageContext.data.product_carousel1}
              pathname={location.pathname}
            />
          </LandPagePost2>
          <LandPagePost>
            <ReactMarkdown source={textCarousel2.textCarousel2} />
          </LandPagePost>
          <LandPagePost2>
            <Carousel
              data={pageContext.data.product_carousel2}
              pathname={location.pathname}
            />
          </LandPagePost2>
          <LandPagePost>
            <Grid>
              <ReactMarkdown source={textBlock3.textBlock3} />
              {pageContext.data.contentful.node.mediaImage3 && (
                <GatsbyImage
                  fluid={pageContext.data.contentful.node.mediaImage3.fluid}
                />
              )}
            </Grid>
          </LandPagePost>
          <LandPagePost2>
            <Grid>
              <GridWithPadding>
                <InnerHTML html={linkInstagram.linkInstagram} />
              </GridWithPadding>
              <GridWithPadding>
                <ReactMarkdown source={textInstagram.textInstagram} />
              </GridWithPadding>
            </Grid>
          </LandPagePost2>
          <LandPagePost>
            <ReactMarkdown source={textCarousel3.textCarousel3} />
          </LandPagePost>
          <LandPagePost2>
            <Carousel
              data={pageContext.data.product_carousel3}
              pathname={location.pathname}
            />
          </LandPagePost2>
          <LandPagePost>
            <ReactMarkdown source={textCarousel4.textCarousel4} />
          </LandPagePost>
          <LandPagePost2>
            <Carousel
              data={pageContext.data.product_carousel4}
              pathname={location.pathname}
            />
          </LandPagePost2>
          <LandPagePost>
            <ReactMarkdown source={textBlock4.textBlock4} />
          </LandPagePost>
          <LandPagePost2>
            <ItemList
              items={pageContext.query}
              db={db}
              pathname={location.pathname}
            />
          </LandPagePost2>
          <LandPagePost>
            {!!seoText && <ReactMarkdown source={seoText.seoText} />}
          </LandPagePost>
        </LandPagePost.Affect>
      </Layout>
    </>
  )
}

export default TrendPageTemplate
