import styled from 'styled-components'
import Item from './Item'
import Slider from './Slider'
import Wrapper from './Wrapper'

const BlogCarousel = styled.div`
  padding-right: 35px;
  padding-left: 35px;
`

BlogCarousel.Slider = Slider
BlogCarousel.Item = Item
BlogCarousel.Wrapper = Wrapper

export default BlogCarousel
