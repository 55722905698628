import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 5px;
  @media screen and (max-width: 576px) {
    padding: 5px;
  }
`

export default Wrapper
