import styled from 'styled-components'
import Affect from './Affect'
import Text from './Text'
import Title from './Title'

const LandPagePost2 = styled.section`
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 35px;
  display: inherit;
  max-width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 1399px) {
  }
  @media screen and (max-width: 992px) {
    padding: 0;
  }

  blockquote {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
`

LandPagePost2.Title = Title
LandPagePost2.Text = Text
LandPagePost2.Affect = Affect

export default LandPagePost2
