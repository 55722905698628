import React, { useCallback } from 'react'
import styled from 'styled-components'
import Grid, { grid } from 'styled-components-grid'
import ProductItem from '../../components/blocks/ProductItem'
import ProductItemDetails from '../../components/blocks/ProductItemDetails'
import LoadableImage from '../../components/elements/LoadableImage'
import priceFormatter from '../../components/elements/PriceFormatter'

const ItemContainer = styled.div`
  padding: 2px;
  font-size: 13px !important;
  @media screen and (max-width: 576px) {
    padding: 5px;
  }
`

const ProductsContainer = styled.div`
  ${grid({})};
  max-width: 1280px;
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    margin: 0 30px;
  }
  @media screen and (max-width: 576px) {
    margin: 0 15px;
  }
`

const ItemList = props => {
  const handleDataLayer = useCallback(
    e => {
      const { simpleSku } = e.target.dataset
      const item = props.items.find(element => element.simpleSku === simpleSku)

      item &&
        window.dataLayer.push({
          //...item,
          amberCatId: props.pathname,
          simpleSku: item.simpleSku,
          // prodColor: ,
          prodBrand: item.prodBrand,
          url_id: props.pathname,
          prodPrice: item.prodPrice,
          prodPriceOld: item.prodPriceOld,
          prodRanking: item.ranking,
          prodTitle: item.prodTitle,
          // merchantName: ,
          pageType: 'TrendPageTemplate',
          event: 'productClick'
        })
    },
    [props.items, props.pathname]
  )
  return (
    <>
      <ProductsContainer>
        {props.items.map((item, index) => (
          <Grid.Unit
            key={index}
            size={{ xs: 1 / 2, sm: 1 / 3, md: 1 / 4, lg: 1 / 5 }}
          >
            <ItemContainer>
              <ProductItem>
                <ProductItem.StyledLink
                  href={item.deepLink || item.deeplink}
                  rel='nofollow noopener'
                  target='_blank'
                  onClick={handleDataLayer}
                  data-simple-sku={item.simpleSku}
                >
                  <LoadableImage
                    src={item.imgUrl1}
                    key={item.imgUrl1}
                    rel='nofollow noopener'
                    alt={item.prodTitle}
                    height={450}
                    data-simple-sku={item.simpleSku}
                  />
                </ProductItem.StyledLink>
                <ProductItemDetails>
                  <ProductItemDetails.Price>
                    <ProductItemDetails.ActualPrice>
                      {priceFormatter(item.prodPrice)}
                    </ProductItemDetails.ActualPrice>
                    {!!item.prodPriceOld && (
                      <ProductItemDetails.Discount>
                        {priceFormatter(item.prodPriceOld)}
                      </ProductItemDetails.Discount>
                    )}
                  </ProductItemDetails.Price>
                  <ProductItemDetails.H3>
                    <ProductItemDetails.StyledLink
                      href={item.deepLink || item.deeplink}
                      rel='nofollow noopener'
                      target='_blank'
                      onClick={handleDataLayer}
                      data-simple-sku={item.simpleSku}
                    >
                      {item.prodTitle}
                    </ProductItemDetails.StyledLink>
                  </ProductItemDetails.H3>
                </ProductItemDetails>
              </ProductItem>
            </ItemContainer>
          </Grid.Unit>
        ))}
      </ProductsContainer>
    </>
  )
}

export default ItemList
